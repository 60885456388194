<template>
  <div class="invoice_check">
    <!-- 查询条件为：发票类型，发票代码、发票号码、开盘日期开始和结束  -->
    <div class="query_condition">
      <div class="query_item">
        <span class="query_item_title">开票日期</span>
        <el-date-picker
            v-model="invoiceTime"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="datePicker"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="query_item">
        <span class="query_item_title">发票代码</span>
        <el-input placeholder="" v-model="invoiceCode" maxlength="25"  @keyup.enter.native="searchList">
          <i class="iconfont iconicon1-30" slot="suffix" @click="searchList"></i>
        </el-input>
      </div>
      <div class="query_item">
        <span class="query_item_title">发票号码</span>
        <el-input placeholder="" v-model="invoiceNo" maxlength="25"  @keyup.enter.native="searchList">
          <i class="iconfont iconicon1-30" slot="suffix" @click="searchList"></i>
        </el-input>
      </div>
      <div class="query_item">
        <span class="query_item_title">发票类型</span>
         <el-select v-model="invoiceType" placeholder="请选择" clearable  class="filter-item" @keyup.enter.native="searchList">
              <el-option v-for="item in invoiceTypeOptions" :key="item.value" :label="item.key" :value="item.value"/>
         </el-select>
      </div>
      
     
      <div class="query_item">
        <el-button type="primary" @click="searchList" style="margin-right: 20px;" >查询</el-button>
        <el-button class="el-button el-button--primary el-button--medium is-plain" @click="reset">重置</el-button>
        </div>
    </div>

    <!-- 开票异常记录 -->
    <comps-table :tableData="tableData" :tableHeader="tableHeader"  :total='total' @current-events="savePage" @size-events='saveSize' class="comp_table" v-if="tableData.length">
    </comps-table>
  </div>

</template>

<script>
  import compsTable from "@/components/table/table";
  import axios from "axios";
  export default {
    components: { compsTable},
    data() {
      return {
        // 搜索
        invoiceTime: [], //发票时间
        invoiceCode:'',//发票代码
        invoiceNo:'',//发票号码
        invoiceType:'',//发票类型
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }],
          onPick: ({ maxDate, minDate }) => {
            this.choiceDate = minDate.getTime()
            if (maxDate) {
              this.choiceDate = ''
            }
          },
          disabledDate: (time) => {

          }
        },
        invoiceTypeOptions:[{'key': '电子普票','value': '1'},{'key': '电子专票','value': '2'},{'key': '纸质普票','value': '3'},{'key': '纸质专票','value': '4'},],

        //列表
        tableData: [{
              "invoiceType": '1',//发票类型 1-电子普票 2-电子专票 3-纸质普票 4-纸质专票
              "buyerName": "1111",
              "buyerTaxId": "0123456",
              "issueType": "0",//开票类型 0-正数发票 1-负数发票
              "invoiceTime": "1222",
              "invoiceAmount": "200.00",
              "issueStatus": "01",//01- 开票成功 02-开票失败
          }], 
        tableHeader: [
          { prop: "invoiceType", label: "发票类型", center: 'left' ,
          formatter: (row) => {
              const typeMap = {
                '1': '电子普票',
                '2': '电子专票',
                '3': '纸质普票',
                '4': '纸质专票',
              }
              return typeMap[row.invoiceType];
            },
          },
          {prop:'buyerName',label:'购买方名称',center: 'left',},
          {prop:'buyerTaxId',label:'购买方税号',center: 'left',
          render: (h, data) => {
              return (
                <span>
                  {data.row.buyerTaxId}
                </span>
              );
            },
          },
          {prop:'issueType',label:'开票类型',center: 'left',
            formatter: (row) => {
              const typeMap = {
                '0': '正数发票',
                '1': '负数发票',
               }
              return typeMap[row.issueType];
            },
          },
          {prop:'invoiceTime',label:'开票日期',center: 'left',
           formatter: (row) => {
              return row.invoiceTime;
            },
          },
          { prop: "invoiceAmount", label: "开票金额", center: 'left',
            render: (h, data) => {
              return (data.row.invoiceAmount>0?<span>{data.row.invoiceAmount}</span>:<span style="color:red">{data.row.invoiceAmount}</span>)
            },
          },
          { prop: "issueStatus", label: "开票状态", center: 'left',
            render: (h, data) => {
              return (
                <span>
                  {data.row.issueStatus == '01' ? '开票成功':'开票失败'}
                </span>
              );
            },
          },
          {
            label: "操作",
            center: 'left' ,
            render: (h, data) => {
              return (
                  <span>
                    <el-button class='btn' type="text"  onClick={() => { this.sync(data.row);}}>
                      同步
                    </el-button>
                      <el-button class='btn' type="text" onClick={() => { this.reopen(data.row);}}>
                        开具
                      </el-button>
                     
                  </span>
                );
            },
          },
        ],
        total: 0,
        pageSize: 20,
        page: 1,

        multipleSelection:[],
        isdeling: false,
      }
    },
    methods: {
      
      
      savePage(val){
        this.page = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },

      
     
       // 开票异常记录
      initTable() {
        this.isLoading = true;
        this.$store.dispatch("FindCommodity", {
          // invoiceTime: this.invoiceTime,
          invoiceTimeStart : this.invoiceTime[0] ? this.invoiceTime[0]: null,
          invoiceTimeEnd : this.invoiceTime[1] ? this.invoiceTime[1] : null,
          invoiceType: this.invoiceType,
          invoiceCode: this.invoiceCode,
          invoiceNo: this.invoiceNo,
          page: this.page,
          rows: this.pageSize,
          companyId : this.getToken('companyId'),
        }).then(res => {
          if (res.success) {
            this.isLoading = false;
            this.tableData = res.data;
            this.total = res.data.length;
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          einvAlert.error("提示",err)
          this.isLoading = false;
        })
      },
      //重置
      reset(){
        this.invoiceTime=[];
        this.invoiceType='';
        this.invoiceCode='';
        this.invoiceNo='';
        
      },
      
     
      
     
      selectUserId(userId) {
        this.userId = userId
      },
      //查询
      searchList() {
         this.initTable()
      },
      
      //同步
      sync(){

      },
      //重开
      reopen(){

      }
     
    },
    mounted() {
    },
  }
</script>
<style lang='less'>
.invoice_check{
 .rei_check_icon {
  font-size: 12px !important;
   margin-right: 8px;
  }
  .el-dialog__header{
    background-color: #409EFF;
    color: #ffffff;
    padding-bottom: 20px;
    font-size: 20px;
  }
}





</style>
<style lang='less' scoped>
 .invoice_check{
   width: 100%;
   .query_condition{
      width: 100%;
      padding: 1%;
      background: #fff;
      margin-bottom: 1%;
      .query_item{
        display: inline-block;
        margin: 10px 12px 10px 0px;
        .query_item_title{
          font-size: 16px;
          color: #333333;
          margin-right: 20px;
        }
        .el-date-editor--daterange{
          width: 400px !important;
        }
        .el-input{
          display: inline-block;
          width: 200px;
        }
      }
    }
   .table{
     width: 100%;
     padding: 1% 1% 2% 1%;
     background: #fff;
   }

 }

 .filter-container {
    padding: 1.04rem 0;
    height: calc(100% - 90px);
  }
</style>
